/* General */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');


html{
  scroll-behavior: smooth;
}

* {
  margin: 0px;
  padding: 0px;
  user-select: none;
}

.home-body{
  font-family: 'Poppins', sans-serif;
}

p {
  color: rgb(85, 85, 85);
}

/* TRANSITION */

a, .btn{
  transition: all 300ms ease;
}

section{
  padding-top: 4vh;
  height: 96vh;
  margin: 0 10rem;
  box-sizing: border-box;
  min-height: fit-content;
}
