#profile{
  display: flex;
  justify-content: center;
  gap: 5rem;
  height: 80vh;
}

.section-pic-container{
  align-self: center;
  display: flex;
  height: 400px;
  width: 400px;
  margin: 0 0;
}

.section-text{
  align-self: center;
  text-align: center;
}

.section-text p{
  font-weight: 600;
}

.section-text-p1{
  text-align: center;
}

.section-text-p2{
  font-size: 1.75rem;
  margin-bottom: 1rem;
}

.title{
  font-size: 3rem;
  text-align: center;
}

#socials-container{
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  gap: 1rem;
}

.icon {
  cursor: pointer;
  height: 2rem;
}

/* Buttons */
.btn-container{
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.btn{
  font-weight: 600;
  transition: all 300ms ease;
  padding: 1rem;
  width: 8rem;
  border-radius: 2rem;
}

.btn-color-1, .btn-color-2 {
  border: rgb(53, 53, 53) 0.1rem solid;
}

.btn-color-1:hover,
.btn-color-2:hover {
  cursor: pointer;
}

.btn-color-1,
.btn-color-2:hover {
  background: rgb(53, 53, 53);
  color: white;
}

.btn-color-1:hover {
  background: rgb(0, 0, 0);
}

.btn-color-2 {
  background: none;
}

.btn-color-2:hover {
  border: rgb(255, 255, 255) 0.1rem solid;
}

.btn-container {
  gap: 1rem;
}