
nav, .nav-links{
  display: flex;
}

nav{
  justify-content: space-around;
  align-items: center;
  height: 17vh;
}

.nav-links{
  gap: 2rem;
  list-style: none;
  font-size: 1.5rem;
}

a{
  color: black;
  text-decoration: none;
  text-decoration-color: white;
}

a:hover{
  color:grey;
  text-decoration: underline;
  text-underline-offset: 1rem;
  text-decoration-color: rgb(181, 181, 181);
}

.logo{
  font-size: 2rem;
}

.logo:hover{
  cursor: default;
}

/* HAMBURGER MENU */
#hamburger-nav{
  display: none;
}

.hamburger-menu{
  position: relative;
  display: inline-block;
}

.hamburger-icon{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  width: 30px;
  cursor: pointer;

}

.hamburger-icon span{
  width: 100%;
  height: 2px;
  background-color: black;
  transition: all 0.3 ease;
}

.menu-links{
  position: absolute;
  top: 100%;
  right: 0%;
  background-color: white;
  width: fit-content;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3 ease;
}

.menu-links a{
  display: block;
  padding: 10px;
  text-align: center;
  font-size: 1.5rem;
  color: black;
  text-decoration: none;
  transition: all 0.3 ease-in-out;
}

.menu-links li{
  list-style: none;
}

.menu-links.open{
  max-height: 300px;
}

.hamburger-icon.open span:first-child{
  transform: rotate(45deg) translate(10px, 5px);
}

.hamburger-icon.open span:nth-child(2){
  opacity: 0;
}

.hamburger-icon.open span:last-child{
  transform: rotate(-45deg) translate(10px, -5px);
}

.hamburger-icon span:first-child{
  transform: none;
}

.hamburger-icon span:nth-child(2){
  opacity: 1;
}

.hamburger-icon span:last-child{
  transform: none;
}


/* Media Queries */
@media screen and (max-width: 1200px){
  #desktop-nav{
    display: none;
  }

  #hamburger-nav{
    display: flex;
  }
}
