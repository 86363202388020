#about{
  position: relative;
}

.about-containers{
  gap: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.about-details-container{
  justify-content: center;
  flex-direction: column;
}

.about-containers,
.about-details-container{
  display: flex;
}

.about-pic{
  border-radius: 2rem;
}

.arrow{
  position: absolute;
  right: -5rem;
  bottom: 2.5rem;
}

.details-container{
  padding: 1.5rem;
  flex: 1;
  background: white;
  border-radius: 2rem;
  border: rgb(53, 53, 53);
  border-color: rgb(163, 163, 163);
  text-align: center;
}

.section-container{
  gap: 4rem;
}

.section-pic-container{
  height: 400px;
  width: 400px;
  
}